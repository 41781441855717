import { notification } from 'antd';
import { ArgsProps, NotificationInstance } from 'antd/lib/notification';
import { Link } from 'react-router-dom';
import { capitalize } from './index';

export const ZINDEX = {
  NO_CONECTION: 2500,
  DEBUG_OVERLAY: 2000,
  JOYRIDE: 1000, // should > 999
  CHAT_CONTAINER: 270,
  LAYOUT_HEADER: 250,
  PAGE_OVERLAY_LOADER: 200,
  SAVE_AS_DRAFT_BTN: 90,
  SUBMIT_TREATMENT_CHECKIN: 9,
};

const getSafeArgs = (args: ArgsProps) => ({
  ...args,
  description: <div dangerouslySetInnerHTML={{ __html: args.description as string }} />,
});

export const notificationDesSafe: NotificationInstance = {
  success: (args: ArgsProps) => notification.success(getSafeArgs(args)),
  error: (args: ArgsProps) => notification.error(getSafeArgs(args)),
  info: (args: ArgsProps) => notification.info(getSafeArgs(args)),
  warning: (args: ArgsProps) => notification.warning(getSafeArgs(args)),
  open: (args: ArgsProps) => notification.open(getSafeArgs(args)),
};

export const notificationApiError = (error: any) => {
  const alertFunction = error?.data?.error === 'Info' ? notificationDesSafe.info : notificationDesSafe.error;
  alertFunction({
    message: error?.data?.error || 'Error',
    description: Array.isArray(error?.data?.message)
      ? error?.data?.message?.map(capitalize).join('<br />')
      : capitalize(error?.data?.message),
  });
};

export const CHAT_TOOL_OFFSET_RIGHT = 10;
export const toggleBodyElmScrollClosure = () => {
  const initialMainHeaderElmPaddingRight = parseInt(
    document.getElementById('MAIN_HEADER_ID')?.style?.paddingRight ?? '0',
    10,
  );

  return (enabled: boolean) => {
    const offset = window.innerWidth - document.body.offsetWidth;
    const mainHeaderElm = document.getElementById('MAIN_HEADER_ID');
    const chatToolElm = document.getElementById('CHAT_TOOL');

    if (enabled) {
      if (mainHeaderElm) mainHeaderElm.style!.paddingRight = `${initialMainHeaderElmPaddingRight}px`;
      if (chatToolElm) chatToolElm.style!.right = `${CHAT_TOOL_OFFSET_RIGHT}px`;
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0px';
    } else {
      if (mainHeaderElm) mainHeaderElm.style!.paddingRight = `${offset}px`;
      if (chatToolElm) chatToolElm.style!.right = `${CHAT_TOOL_OFFSET_RIGHT + offset}px`;
      document.body.style.paddingRight = `${offset}px`;
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
    }
  };
};

export const toggleBodyElmScroll = toggleBodyElmScrollClosure();

export const tabLabelNavigation = (label: string, to: string) => (
  <Link className="text-inherit" to={to}>
    {label}
  </Link>
);

export const sIfPlural = (n: number): string => (n === 1 ? '' : 's');
