import { InputRef } from 'antd';
import { ColumnType, SorterResult, SortOrder } from 'antd/lib/table/interface';
import { getColumnSearchProps } from './table-search-column';
import { ITableColumnProperties } from './useTableSettings';
import { isArray } from 'lodash';
import { ReactNode, RefObject } from 'react';
import { TWindowSize, TWindowSizeKey } from '../../hooks/useWindowSize';

export const COMMON_SORTING: { sorter: boolean; sortDirections: SortOrder[] } = {
  sorter: true,
  sortDirections: ['descend', 'ascend'],
};

export const ALL_MEAN_SKIP_FILTER = 'ALL_MEAN_SKIP_FILTER';

export const DEFAULT_PAGINATION_CONFIG = {
  pageSizeOptions: ['10', '20'],
  position: ['bottomRight'] as any,
  showSizeChanger: true,
  showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
};

export type TSortOrderParam = 'asc' | 'desc' | '';
export type TSorterParams = { sort: string; order: TSortOrderParam };

const SORT_ORDER__PARAM_TO_ANTD: Record<string, SortOrder> = {
  asc: 'ascend',
  desc: 'descend',
};

export const getSortOrderAntd = (columnDataIndex: string | string[], sorterParams: TSorterParams): SortOrder => {
  const sortField: string = isArray(columnDataIndex) ? columnDataIndex.join(',') : columnDataIndex;
  return sorterParams.order && sorterParams.sort === sortField ? SORT_ORDER__PARAM_TO_ANTD[sorterParams.order] : null;
};

export const getSorterParams = (sorterAntd: SorterResult<any>): TSorterParams => {
  const sortField: string = isArray(sorterAntd.field) ? sorterAntd.field.join(',') : (sorterAntd.field as string);
  return {
    sort: sorterAntd.order && sortField ? sortField : '',
    order: sorterAntd.order === 'descend' ? 'desc' : sorterAntd.order === 'ascend' ? 'asc' : '',
  };
};

// TODO: let apply it for all
export const getColumnsWithSorter = (
  colums: ITableColumnProperties[],
  sorterParams: TSorterParams,
): ITableColumnProperties[] =>
  colums.map((datum) => ({
    ...datum,
    sortOrder: getSortOrderAntd(datum.dataIndex, sorterParams),
  }));

export const fieldsToCleanObject = (fields: string[]): Record<string, string> => {
  const objectData: Record<string, string> = {};
  fields.forEach((field: string) => {
    objectData[field] = '';
  });

  return objectData;
};

// TODO: will use . instead of , here:
export const getFiltersParam = (filters: any) =>
  Object.entries<string[] | undefined>(filters).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      const convertedKey = key.split('.').join(','); // for ex: from patient.fullName to patient,fullName
      delete acc[key];
      acc[convertedKey] = value?.join(',') || '';

      return acc;
    },
    {} as Record<string, string>,
  );

type TWindowCastKey = Exclude<TWindowSizeKey, 'isSm'>;

export interface ITableColumnPropertiesRaw<T = any>
  extends Pick<ColumnType<T>, 'title' | 'fixed' | 'width' | 'render' | 'filterMultiple' | 'ellipsis' | 'filters'> {
  dataIndex: string | string[];
  titleString?: string; // for table settings only
  searchable?: boolean;
  searchPlaceHolder?: string;
  customSearchRender?: (props: { text: string; record: T; children?: ReactNode }) => any;
  sortable?: boolean;
  windowCast?: Partial<Record<TWindowCastKey, (v: ITableColumnPropertiesRaw) => ITableColumnPropertiesRaw>>;
}

type TBuildTableColumnsArgs = {
  columns: ITableColumnPropertiesRaw[];
  queryParams: any;
  searchInputRef: RefObject<InputRef>;
  windowSize: TWindowSize;
};

// TODO: let apply it for all
export const buildTableColumns = ({
  columns,
  queryParams,
  searchInputRef,
  windowSize,
}: TBuildTableColumnsArgs): ITableColumnProperties[] => {
  const { isMd, isLg } = windowSize;
  const sorterParams = {
    order: queryParams.order,
    sort: queryParams.sort,
  };

  return columns.map((colum) => {
    const { sortable, searchable, render, customSearchRender, filters, width, windowCast, ...columnRest } = colum;
    const dataIndexString: string = isArray(colum.dataIndex) ? colum.dataIndex.join(',') : colum.dataIndex;
    const filteredValue = queryParams[dataIndexString];

    if (searchable && render) {
      console.error(`[searchable] must use with [customSearchRender] at [colum.dataIndex]: ${colum.dataIndex}`);
    }

    const getCastColumnRest = () => {
      if (isLg && windowCast?.isLg) return (windowCast.isLg as any)(columnRest);
      if (isMd && windowCast?.isMd) return (windowCast.isMd as any)(columnRest);
      return columnRest;
    };

    return {
      width: width || 200,
      ...getCastColumnRest(),
      ...(sortable
        ? {
            ...COMMON_SORTING,
            sortOrder: getSortOrderAntd(dataIndexString, sorterParams),
          }
        : {}),
      ...(searchable
        ? getColumnSearchProps({
            value: filteredValue,
            wrapper: customSearchRender,
            searchPlaceHolder: colum.searchPlaceHolder,
            searchInputRef,
          })
        : { render }),
      ...(filters
        ? {
            filters,
            filteredValue: filteredValue ? filteredValue.split(',') : [],
          }
        : {}),
    };
  });
};

// export const INIT_TABLE_PARAMS: ITableParams = { page: '1', perPage: '10', sort: '', order: '' };
export const INIT_TABLE_PARAMS: any = { page: '1', perPage: '10', sort: '', order: '' };
