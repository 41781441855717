import { EyeOutlined } from '@ant-design/icons';
import { Button, Col, Image, message, Row, Typography, Upload, Popover, ModalProps } from 'antd';
import { FC, useState } from 'react';
import { ReactComponent as UploadSVG } from 'assets/images/Upload.svg';
import { formatSaveImage } from 'helpers';
import { useGetSignedUrlMutation } from 'services/api';
import { useUpdateSubmissionMutation } from 'services/operations/operationsApi';
import { uploadFile } from 'services/s3-api/endpoints';
import { beforeFileUploadImg } from 'utils';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { NiceAntDrawer } from 'components/nice-components/nice-ant-drawer/nice-ant-drawer';
import { ButtonDeleteIcon } from 'components/buttons/button-delete-icon';
import { IImage } from '@repo/ui/src/types/sq-types';

const { Dragger } = Upload;

interface IProps extends NiceModalHocProps, ModalProps {
  title: any;
  submissionInfo?: any;
  objectKey?: any;
  fetchCase?: any;
}

export const AccordionUploadModal: FC<IProps> = NiceModal.create(({ title, submissionInfo, objectKey, fetchCase }) => {
  const modal = useModal();
  const [isShowImgOptions, setIsShowImgOptions] = useState(false);
  const [visible, setShowPreviewImage] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(submissionInfo[objectKey]);
  const [currentPreview, setCurrentPreviewImage] = useState<any>(0);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [getSignedUrl] = useGetSignedUrlMutation();
  const [updatePatient, { isLoading }] = useUpdateSubmissionMutation();
  const handleFileChange = ({ fileList, file }: any) => {
    const { status, response } = file;

    if (status === 'done') {
      const { imageUrl } = response;
      const newFile = { ...file, url: imageUrl, status: 'done' };
      setInitialValues([...initialValues.filter((img: IImage) => img?.uid !== file?.uid), newFile]);
      message.success(`${file.name} file uploaded successfully.`);
    }
    if (status === 'error') {
      const newList = initialValues.filter((f: any) => f.uid !== file?.uid) || [];
      setInitialValues(newList);
      message.error(`${file.name} file upload failed.`);
    }
    if (status === 'uploading') {
      setInitialValues([...initialValues, ...fileList]);
    }
  };

  const uploadImageRequest = async ({ file, onSuccess, filename }: any) => {
    const { name, type } = file;

    if (file) {
      setShowSaveBtn(true);
      try {
        const fileData = {
          fileName: name,
          mimeType: type,
          folderName: filename,
        };
        if (fileData?.mimeType !== '') {
          const signedUrl = await getSignedUrl(fileData).unwrap();
          const options: any = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
          if (signedUrl?.url) {
            await uploadFile(signedUrl?.url, file, options).then((_res: any) => {
              setShowSaveBtn(false);
            });
          }

          const imageUrl = signedUrl && signedUrl?.url?.split('?')[0];
          onSuccess({ imageUrl, filename });
        }
      } catch (e) {
        throw new Error(`Upload profile pic error: ${e}`);
      }
    }
  };

  const saveUploadedImages = async () => {
    if (objectKey) {
      const { id } = submissionInfo;

      const updateData: any = {
        [objectKey]: formatSaveImage([...initialValues]),
      };
      await updatePatient({ id, payload: updateData }).unwrap();
      fetchCase();
      modal.remove();
    }
  };

  const previewImage = (index: number) => {
    setCurrentPreviewImage(index);
    setShowPreviewImage(true);
  };

  const deleteImage = (url: string) => {
    const filterImages = initialValues.filter((item: any) => item.url !== url);
    setInitialValues(filterImages);
  };

  const handleCancel = () => {
    modal.remove();
  };

  return (
    <NiceAntDrawer
      modal={modal}
      title={`Upload ${title}`}
      width={500}
      onClose={handleCancel}
      maskClosable={false}
      footer={
        <div className="flex justify-end">
          <Button className="mr-4" style={{ minWidth: 160 }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ minWidth: 160 }}
            loading={isLoading}
            disabled={showSaveBtn || initialValues.find((item: any) => !item.url)}
            onClick={saveUploadedImages}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className="flex justify-center">
        <Dragger
          name="images"
          multiple
          showUploadList={false}
          beforeUpload={beforeFileUploadImg}
          customRequest={uploadImageRequest}
          onDrop={() => {}}
          onChange={handleFileChange}
        >
          <div className="flex p-2">
            <div className="ml-2 mr-4">
              <p className="ant-upload-drag-icon m-0">
                <UploadSVG />
              </p>
            </div>
            <div className="flex items-center">
              <p className="upload-text text-left">Click or drag file to this area to upload</p>
            </div>
          </div>
        </Dragger>
      </div>
      <div className="uploaded-image-container hide-preview-title mt-4">
        <div className="preview-container">
          <Row gutter={16}>
            {initialValues.map((item: any, index: number) => {
              const { url, status, uid } = item;
              return (
                <Col className="gutter-row" span={12} key={url || uid}>
                  {status && status !== 'done' ? (
                    <div
                      className="mb-2 flex min-w-full items-center justify-center"
                      style={{ minHeight: 140, backgroundColor: '#f5f5f5' }}
                    >
                      <Typography.Text>Uploading... </Typography.Text>
                    </div>
                  ) : (
                    <div
                      className="small-image-container mb-2 w-full cursor-pointer"
                      style={{ minHeight: 140 }}
                      onMouseOver={() => setIsShowImgOptions(!isShowImgOptions)}
                      onMouseLeave={() => setIsShowImgOptions(false)}
                      onFocus={() => undefined}
                      onBlur={() => undefined}
                    >
                      <img alt="" src={url} className="h-full" />
                      <div className="overlay">
                        <div className="image-backdrop" />
                        <div className="image-preview-btn flex h-full w-full flex-row items-center justify-center">
                          <Popover content="Preview">
                            <EyeOutlined onClick={() => previewImage(index)} className="text-2xl text-gray-300" />
                          </Popover>
                          <ButtonDeleteIcon onConfirm={() => deleteImage(url)} />
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              );
            })}
          </Row>
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup
              preview={{ visible, onVisibleChange: (vis) => setShowPreviewImage(vis), current: currentPreview }}
            >
              {initialValues.map(({ url }: any) => (
                <Image key={url} src={url} />
              ))}
            </Image.PreviewGroup>
          </div>
        </div>
      </div>
    </NiceAntDrawer>
  );
});
