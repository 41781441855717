import { ALL_MEAN_SKIP_FILTER } from '@repo/ui/src/components/table/utils';
import { ESubmissionStatus } from '@repo/ui/src/utils';

export const SUBMISSION_STATUSES_SELECT_OPTIONS = [
  { label: 'All', value: ALL_MEAN_SKIP_FILTER },
  { label: 'Draft', value: ESubmissionStatus.DRAFT },
  { label: 'Awaiting Files', value: ESubmissionStatus.AWAITING_FILES },
  { label: 'New Cases', value: ESubmissionStatus.SUBMITTED },
  { label: 'Awaiting Designer Acceptance', value: ESubmissionStatus.AWAITING_DESIGNER_ACCEPTANCE },
  { label: 'Awaiting STL Verification', value: ESubmissionStatus.AWAITING_STL_VERIFICATION },
  { label: 'Awaiting Specialist Acceptance', value: ESubmissionStatus.AWAITING_SPECIALIST_ACCEPTANCE },
  { label: 'Awaiting Instruction', value: ESubmissionStatus.AWAITING_INSTRUCTION },
  { label: 'On Hold', value: ESubmissionStatus.ON_HOLD },
  { label: 'Not Suitable Pending', value: ESubmissionStatus.NOT_SUITABLE_PENDING },
  { label: 'Awaiting Design', value: ESubmissionStatus.AWAITING_DESIGN },
  { label: 'Awaiting Advice', value: ESubmissionStatus.AWAITING_ADVICE },
  { label: 'Review Design', value: ESubmissionStatus.REVIEW_DESIGN },
  { label: 'Awaiting Dentist Approval', value: ESubmissionStatus.AWAITING_DENTIST_APPROVAL },
  { label: 'Approved', value: ESubmissionStatus.APPROVED },
  { label: 'Review STL Files', value: ESubmissionStatus.REVIEW_STL_FILES },
  { label: 'With Manufacturer', value: ESubmissionStatus.WITH_MANUFACTURER },
  { label: 'With 32Co', value: ESubmissionStatus.WITH_32CO },
  { label: 'Shipped', value: ESubmissionStatus.SHIPPED },
  { label: 'Archived', value: ESubmissionStatus.ARCHIVED },
  { label: 'In Treatment', value: ESubmissionStatus.IN_TREATMENT },
  { label: 'Completed', value: ESubmissionStatus.COMPLETED },
  { label: 'Not Suitable', value: ESubmissionStatus.NOT_SUITABLE },
  { label: 'Expired', value: ESubmissionStatus.EXPIRED },
] as const;

export const SUBMISSION_LISTING_TARGET_MAPPING: Record<string, string> = {
  notAcceptedCount: 'NOT_ACCEPTED',
  aboutToBeDueCount: 'ABOUT_TO_BE_DUE',
  overdueCount: 'OVER_DUE',
  notSuitableReviewPendingCount: 'NOT_SUITABLE_REVIEW_PENDING',
  stlRequiredCount: 'STL_REQUIRED',
  notAssignedCount: 'NOT_ASSIGNED',
  pendingProSubmissionReviewCount: 'PENDING_PRO_SUBMISSION_REVIEW',
  pendingLiteSubmissionReviewCount: 'PENDING_SOLO_SUBMISSION_REVIEW',
  pendingToManufacturerCount: 'PENDING_TO_MANUFACTURER',
  priorityCount: 'PRIORITY',
  stlIssuesReportRequireReviewCount: 'STL_ISSUES_REPORT_REQUIRE_REVIEW',
  stlIssuesReportRequireStlUploadCount: 'STL_ISSUES_REPORT_REQUIRE_UPLOAD_STL',
};
