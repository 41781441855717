export const GB = {
  translation: {
    currency: {
      symbol: '£',
      code: 'GBP',
      poundRate: 1,
    },
    // ADMIN
    ADMIN: {
      DENTIST: {
        'GDC number': 'GDC number',
      },
    },
    // CONSUMER
    SIGN_UP: {
      dentalLicensePlaceholder: 'E.g. General Dental Council Number in UK',
      'Leading manufacturers are competing for your business with lab bills from £300':
        'Leading manufacturers are competing for your business with lab bills from £300',
    },
    offeredBy32Co: 'Offered by 32Co',
    commingSoon: 'Coming Soon',
    // CALCULATOR
    'Note, if you don’t know then pick 1 refinement. You can always buy extra refinement aligners for between £200-450 GBP':
      'Note, if you don’t know then pick 1 refinement. You can always buy extra refinement aligners for between £200-450 GBP',
    SUBMISSION: {
      'Review your treatment proposal; your specialist orthodontist can make any necessary tweaks. There’s nothing to pay up-front but if your patient doesn’t go ahead there is a cancellation fee of £150.':
        'Review your treatment proposal; your specialist orthodontist can make any necessary tweaks. There’s nothing to pay up-front but if your patient doesn’t go ahead there is a cancellation fee of £150.',
      '2. It’s rare but sometimes patients aren’t suitable for treatment. If so, we will share a report and there is a service fee of £40.':
        '2. It’s rare but sometimes patients aren’t suitable for treatment. If so, we will share a report and there is a service fee of £40.',
      "3. If we plan your treatment but you don’t order the aligners there's a service fee of £150.":
        "3. If we plan your treatment but you don’t order the aligners there's a service fee of £150.",
      'and the £150 expiry fee is applied.': 'and the £150 expiry fee is applied.',
    },
    PROPOSAL: {
      'average cost for refinement aligners £200-400*': 'average cost for refinement aligners £200-400*',
    },
    REPLACEMENT: {
      'If you’ve tried the above or it doesn’t apply then please request a replacement. Please note, a charge of £25 per aligner may apply for lost or accidentally damaged aligners.':
        'If you’ve tried the above or it doesn’t apply then please request a replacement. Please note, a charge of £25 per aligner may apply for lost or accidentally damaged aligners.',
    },
  },
};
