import { NiceModalHandler } from '@ebay/nice-modal-react';
import { Drawer, DrawerProps } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

type TNiceAntDrawerProps = DrawerProps & {
  modal?: NiceModalHandler | null;
  disabledPrompt?: boolean;
};

export const NiceAntDrawer: React.FC<TNiceAntDrawerProps> = (props) => {
  const {
    children,
    afterOpenChange,
    onClose,
    className,
    modal = null,
    destroyOnClose = true,
    bodyStyle,
    style,
    disabledPrompt = false,
    ...restProps
  } = props;

  useEffect(() => {
    if (modal?.visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modal?.visible]);

  useEffect(
    () => () => {
      document.body.style.overflow = 'unset';
    },
    [],
  );

  const handleCancel = useCallback(
    (e: any) => {
      onClose?.(e);
      modal?.hide();
    },
    [onClose, modal],
  );

  const onAfterClose = useCallback(
    (open: boolean) => {
      afterOpenChange?.(open);
      if (!open) {
        if (destroyOnClose) modal?.remove();
      }
    },
    [afterOpenChange, modal, destroyOnClose],
  );

  return (
    <Drawer
      open={modal?.visible}
      onClose={handleCancel}
      afterOpenChange={onAfterClose}
      className={classNames('nice-ant-drawer', className)}
      {...restProps}
    >
      <Prompt
        when={!disabledPrompt}
        message={(_location, action) => {
          if (action === 'POP') {
            modal?.remove();
          }
          return true;
        }}
      />
      {typeof children === 'function' ? (children as any)?.({ modal }) : children}
    </Drawer>
  );
};
