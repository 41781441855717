export const IE = {
  translation: {
    currency: {
      symbol: '€',
      code: 'EUR',
      poundRate: 1.15,
    },
    // ADMIN
    ADMIN: {
      DENTIST: {
        'GDC number': 'Dental License Number',
      },
    },
    // CONSUMER
    SIGN_UP: {
      dentalLicensePlaceholder: 'E.g. Irish Dental Council Registration Number',
      'Leading manufacturers are competing for your business with lab bills from £300':
        'Leading manufacturers are competing for your business with lab bills from €350',
    },
    offeredBy32Co: 'Offered by 32Co',
    commingSoon: 'Coming Soon',
    // CALCULATOR
    'Note, if you don’t know then pick 1 refinement. You can always buy extra refinement aligners for between £200-450 GBP':
      'Note, if you don’t know then pick 1 refinement. You can always buy extra refinement aligners for between €230-520',
    SUBMISSION: {
      'Review your treatment proposal; your specialist orthodontist can make any necessary tweaks. There’s nothing to pay up-front but if your patient doesn’t go ahead there is a cancellation fee of £150.':
        'Review your treatment proposal; your specialist orthodontist can make any necessary tweaks. There’s nothing to pay up-front but if your patient doesn’t go ahead there is a cancellation fee of €175.',
      '2. It’s rare but sometimes patients aren’t suitable for treatment. If so, we will share a report and there is a service fee of £40.':
        '2. It’s rare but sometimes patients aren’t suitable for treatment. If so, we will share a report and there is a service fee of €45.',
      "3. If we plan your treatment but you don’t order the aligners there's a service fee of £150.":
        "3. If we plan your treatment but you don’t order the aligners there's a service fee of €175.",
      'and the £150 expiry fee is applied.': 'and the €175 expiry fee is applied.',
    },
    PROPOSAL: {
      'average cost for refinement aligners £200-400*': 'average cost for refinement aligners €250-450*',
    },
    REPLACEMENT: {
      'If you’ve tried the above or it doesn’t apply then please request a replacement. Please note, a charge of £25 per aligner may apply for lost or accidentally damaged aligners.':
        'If you’ve tried the above or it doesn’t apply then please request a replacement. Please note, a charge of €28.75 per aligner may apply for lost or accidentally damaged aligners.',
    },
  },
};
