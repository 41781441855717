import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { logout } from 'redux/authSlice';
import {
  ICasesCountResponse,
  ICountryOption,
  ISignedURLRequest,
  IUploadFileRequest,
  TCurrencyOption,
  TDownloadStlsArchiveResponse,
  TDownloadStlsResponse,
  TGetCountriesResponse,
  TGetFormOptionsResponse,
  TGetFormOptionsResponseRaw,
  TMetaOptionsResponse,
} from './apiTypes';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api-alpha.32co.com';
export const VERSION = process.env.REACT_APP_BASE_URL_VERSION || 'v1';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'include',
});
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }
  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'SubmissionsView',
    'Cases',
    'Users',
    'Practices',
    'Bills',
    'Payments',
    'Discounts',
    'SKUS',
    'Modifications',
    'Proposals',
    'Releases',
    'Notion',
    'Notes',
    'UserEduContent',
    'Calculator',
    'Orders',
    'STLIssues',
    'RoleSettings',
    'Corporates',
    'SubmissionTimeline',
    'Technicians',
    'Holiday',
    'MetaDashboard',
    'Fulfillments',
    'Replacements',
    'DentistProfileNotes',
    'Retainers',
    'InterestedPatients',
    'CompletedTreatment',
    'CompletedTreatmentDetails',
    'CaseStudies',
    'CaseStudyDetails',
  ],
  endpoints: (builder) => ({
    getSignedUrl: builder.mutation<{ url: string; uploadedAt?: string }, ISignedURLRequest>({
      query: (credentials) => ({
        url: `/${VERSION}/admin/files/signed-url`,
        method: 'POST',
        body: credentials,
      }),
    }),
    uploadFile: builder.mutation<void, IUploadFileRequest>({
      query: (credentials) => ({
        url: credentials.url,
        method: 'PUT',
        body: credentials.file,
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    }),

    getDashboardCasesCount: builder.query<ICasesCountResponse, void>({
      query: () => `/${VERSION}/admin/dashboard/submissions/count`,
    }),
    getFormOptions: builder.query<TGetFormOptionsResponse, { query: string }>({
      query: ({ query }) => `/${VERSION}/admin/forms/options?${query}`,
      transformResponse: (response: TGetFormOptionsResponseRaw) => {
        const finishOption = response
          .find((v) => v.fieldKey === 'finishOption')
          ?.options.map((v) => ({ label: v.label, value: v.value }));
        const materialType = response
          .find((v) => v.fieldKey === 'materialType')
          ?.options.map((v) => ({ label: v.label, value: v.value }));
        return {
          finishOption,
          materialType,
        };
      },
    }),
    getCountries: builder.query<TGetCountriesResponse, void>({
      query: () => `/${VERSION}/admin/forms/countries`,
      transformResponse: (response: TGetCountriesResponse) => response.sort((a, b) => (a.name > b.name ? 1 : -1)),
    }),
    getMetaOptions: builder.query<TMetaOptionsResponse, string>({
      query: (q) => `/${VERSION}/admin/forms/options?formName=${q}`,
    }),
    downloadStlsAsZip: builder.mutation<TDownloadStlsArchiveResponse, { treatmentDesign: string; submission: string }>({
      query: (payload) => ({
        url: `/${VERSION}/admin/treatment-designs/download-archive`,
        method: 'POST',
        body: payload,
      }),
    }),
    getDownloadStlsAsZipStatus: builder.query<TDownloadStlsResponse[], { treatmentDesign: string | undefined }>({
      query: ({ treatmentDesign }) => `/${VERSION}/admin/treatment-designs/${treatmentDesign}/download-archive`,
    }),
    deleteDownloadingStlsAsZip: builder.mutation<{ message: string }, { treatmentDesign: string; downloadId: string }>({
      query: ({ treatmentDesign, downloadId }) => ({
        url: `/${VERSION}/admin/treatment-designs/${treatmentDesign}/download-archive/${downloadId}`,
        method: 'DELETE',
      }),
    }),
    getCountryOptions: builder.query<ICountryOption[], void>({
      query: () => `/${VERSION}/admin/forms/countries`,
    }),
    getCurrencyOptions: builder.query<TCurrencyOption[], void>({
      query: () => `/${VERSION}/admin/forms/currencies`,
    }),
  }),
});

export const {
  useGetSignedUrlMutation,
  useUploadFileMutation,
  useGetDashboardCasesCountQuery,
  useGetFormOptionsQuery,
  useLazyGetCountriesQuery,
  useGetMetaOptionsQuery,
  useDownloadStlsAsZipMutation,
  useLazyGetDownloadStlsAsZipStatusQuery,
  useDeleteDownloadingStlsAsZipMutation,
  useGetCountryOptionsQuery,
  useGetCurrencyOptionsQuery,
} = api;
