import { Input, Space, Button, InputRef, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { FC, ReactNode, RefObject } from 'react';
import Highlighter from 'react-highlight-words';
import { ITableColumnProperties } from './useTableSettings';

export const getColumnSearchProps = <T,>({
  searchInputRef,
  value,
  wrapper: Wrapper,
  searchPlaceHolder = 'Search',
}: {
  searchInputRef: RefObject<InputRef>;
  value?: string;
  wrapper?: FC<{ text: string; record: T; children: ReactNode }>;
  searchPlaceHolder?: string;
}): Partial<ITableColumnProperties<T>> => ({
  filteredValue: value ? [value] : [],
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const handleSearch = () => {
      setSelectedKeys([(selectedKeys[0] as string)?.trim()]);
      confirm();
    };

    return (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInputRef}
          placeholder={searchPlaceHolder}
          value={(selectedKeys[0] as string) || ''}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/[^\w\s-]/gi, '');
            setSelectedKeys(inputValue ? [inputValue] : []);
          }}
          onPressEnter={handleSearch}
          className="mb-2 block"
        />
        <Space>
          <Button
            disabled={!value}
            onClick={() => {
              clearFilters?.();
              confirm();
            }}
            className="w-20"
          >
            Reset
          </Button>
          <Button
            type="primary"
            className="btn-primary-redesigned"
            onClick={handleSearch}
            icon={<SearchOutlined />}
            disabled={!selectedKeys[0]}
          >
            Search
          </Button>
        </Space>
      </div>
    );
  },
  filterIcon: (filtered: boolean) => (
    <Tooltip title={searchPlaceHolder} mouseEnterDelay={0.5}>
      <SearchOutlined
        className={classNames('-mb-2 -ml-2 p-1 text-lg text-current', filtered && '!text-primaryColor')}
      />
    </Tooltip>
  ),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInputRef.current?.select(), 100);
    }
  },
  render: (text, record) => {
    const highlighterElm = (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={value ? [value] : []}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    );

    return Wrapper ? (
      <Wrapper text={text} record={record}>
        {highlighterElm}
      </Wrapper>
    ) : (
      highlighterElm
    );
  },
});
