import { FC } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';
import { PopconfirmDelete } from '@repo/ui/src/components/popovers/popconfirm-delete';

type TProps = {
  onConfirm: () => void;
  red?: boolean;
};

export const ButtonDeleteIcon: FC<TProps> = ({ onConfirm, red }) => (
  <PopconfirmDelete
    onConfirm={onConfirm}
    okButtonProps={{ style: { height: 32, borderRadius: 8, fontSize: 14, paddingLeft: 15, paddingRight: 15 } }}
    cancelButtonProps={{ style: { height: 32, borderRadius: 8, fontSize: 14, paddingLeft: 15, paddingRight: 15 } }}
  >
    <Button type="text" className="!p-0 disabled:hidden">
      <DeleteOutlined className={twMerge('ml-2.5 cursor-pointer text-xl', red ? 'text-red-500' : 'text-gray-300')} />
    </Button>
  </PopconfirmDelete>
);
