import './form-item-control.less';
import React, { FC, useEffect } from 'react';
import { Form, FormInstance, FormItemProps } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import classNames from 'classnames';

export interface IFormItemControlProps extends FormItemProps {
  name?: NamePath;
  form?: FormInstance<any>;
  formListName?: string;
  visible?: (formInstance: FormInstance) => boolean; // to trigger this feature, have to pass `shouldUpdate` OR `dependencies` prop as well
  disableError?: boolean;
  isRequired?: boolean;
  children?: ((form: any) => React.ReactNode) | React.ReactNode;
}

export const FormItemControl: FC<IFormItemControlProps> = (props) => {
  const {
    name,
    isListField,
    children,
    formListName,
    visible,
    disableError,
    validateTrigger,
    className,
    isRequired,
    label,
    ...propsRest
  } = props;

  const combinedName = formListName ? [formListName, ...((name as Array<any>) || [])] : (name ?? '');
  const form = Form.useFormInstance();
  const watchValue = Form.useWatch(combinedName, form);

  useEffect(() => {
    if (isListField && !formListName) {
      console.error('Warning: Need to provide [formListName] property for FormItemControl component!!');
    }
  }, []);

  useEffect(() => {
    if (form?.getFieldError(combinedName)?.length) {
      form.validateFields([combinedName]);
    }
  }, [watchValue]);

  const childrenWithProps = React.isValidElement(children)
    ? // TODO: change [className: `${children.props.className} nice-input`] then check all FormItemControls have children className Props
      // like [className: cn('custom-signin-input',]
      React.cloneElement(children, { formItemProps: { name: props?.name }, className: `nice-input` } as any)
    : children;

  if (visible)
    return (
      <Form.Item noStyle shouldUpdate={propsRest.shouldUpdate} dependencies={propsRest.dependencies}>
        {(formInstance: any) => (visible(formInstance) ? childrenWithProps : null) as any}
      </Form.Item>
    );

  return (
    <Form.Item
      name={name}
      isListField={isListField}
      {...propsRest}
      validateTrigger={validateTrigger || ['onSubmit', 'onBlur']}
      validateStatus={disableError ? 'success' : undefined}
      help={disableError ? '' : undefined}
      className={classNames('form-item-control nice-form-item mb-0', className)}
      label={
        isRequired && label ? (
          <span>
            {label}
            <span>*</span>
          </span>
        ) : (
          label
        )
      }
      required={false}
    >
      {childrenWithProps}
    </Form.Item>
  );
};
