import './accordion.styles.less';
import { DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Tooltip } from 'antd';
import { FC } from 'react';
import { UploadStlModal } from 'pages/operations/components/upload-stl-modal/upload-stl-modal';
import NiceModal from '@ebay/nice-modal-react';
import { PermissionCheck } from 'components/permission-check/permission-check';
import classNames from 'classnames';
import { AccordionUploadModal } from './accordion-upload-modal/accordion-upload-modal';

const { Panel } = Collapse;

interface IAccordion {
  children: any;
  title: any;
  extra?: boolean;
  open?: boolean;
  submissionInfo?: any;
  objectKey?: any;
  fetchCase?: any;
  isDisableStlAddMore?: boolean;
}

export const Accordion: FC<IAccordion> = ({
  children,
  title,
  extra,
  open,
  submissionInfo,
  objectKey,
  fetchCase,
  isDisableStlAddMore,
}) => {
  const showUploadStlModal = () => {
    if (submissionInfo) {
      NiceModal.show(UploadStlModal, {
        refetch: fetchCase,
        submissionInfo,
      });
    }
  };

  const showuploadImgsModal = () => {
    if (submissionInfo) {
      NiceModal.show(AccordionUploadModal, {
        title,
        submissionInfo,
        objectKey,
        fetchCase,
      });
    }
  };

  const addMoreFiles = (event: any) => {
    event.stopPropagation();
    if (objectKey === 'imprImages') {
      showUploadStlModal();
    } else {
      showuploadImgsModal();
    }
  };

  const genExtra = () => (
    <div className="z-5 mr-6">
      <PermissionCheck resourceKey="operation_submission" actionKey="edit">
        <Tooltip title={isDisableStlAddMore ? '🚫Upload files in the report' : ''}>
          <button
            className={classNames('add-more-btn', isDisableStlAddMore ? '!cursor-not-allowed opacity-50' : '')}
            type="button"
            onClick={(e) => (isDisableStlAddMore ? null : addMoreFiles(e))}
          >
            <span className="mr-2">Add more</span>
            <PlusCircleOutlined />
          </button>
        </Tooltip>
      </PermissionCheck>
    </div>
  );

  const expandIcon = ({ isActive }: { isActive?: boolean }) => (
    <Button
      className="collapse-btn flex items-center justify-center"
      shape="circle"
      size="small"
      icon={<DownOutlined className="!text-xs" rotate={isActive ? 180 : 0} />}
    />
  );

  return (
    <div className="custom-accordion custom-shadow">
      <Collapse expandIconPosition="end" defaultActiveKey={open ? ['1'] : []} expandIcon={expandIcon}>
        <Panel header={title} extra={extra ? genExtra() : null} key="1">
          {children}
        </Panel>
      </Collapse>
    </div>
  );
};
