import React, { useState } from 'react';
import cn from 'classnames';
import { MailOutlined } from '@ant-design/icons';
import './signin-form-item.styles.less';
import { FormInstance, Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { FormItemControl } from '@repo/ui/src/components/forms/form-item-control/form-item-control';

interface ISigninFormItemProps {
  form: FormInstance;
  formName: string;
  label?: string;
  children: any;
  formRules?: Rule[];
  formDependencies?: NamePath[] | undefined;
  className?: string;
  hasEmailSuffix?: boolean;
}

export const SigninFormItem: React.FC<ISigninFormItemProps> = ({
  form,
  formName,
  children,
  label,
  formRules,
  formDependencies,
  className,
  hasEmailSuffix = false,
}) => {
  const [isFilled, setIsFilled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormItemControl
      form={form}
      name={formName}
      label={label}
      rules={formRules}
      dependencies={formDependencies}
      className={cn('custom-form-item', { 'small-label': isFocused || isFilled }, className)}
    >
      {React.cloneElement(children, {
        className: cn('custom-signin-input', { lowercase: hasEmailSuffix }),
        suffix: hasEmailSuffix ? <MailOutlined /> : undefined,
        onChange: (e: any) => {
          setIsFilled(e.target.value.trim().length > 0);
        },
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
      })}
    </FormItemControl>
  );
};
