import './index.less';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import * as serviceWorker from './serviceWorker';
import { initMixpanel } from 'utils/mixpanel/mixpanel';
import '@repo/i18n';

// Initialize mixpanel
initMixpanel();

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <NiceModal.Provider>
            <App />
          </NiceModal.Provider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
