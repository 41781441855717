import moment, { Moment } from 'moment';
import * as Sentry from '@sentry/react';

export function capitalize(s: string) {
  if (!s) return s;
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export enum ESubmissionStatus {
  ALL = '',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  AWAITING_FILES = 'AWAITING_FILES',
  SUBMITTED = 'SUBMITTED',
  AWAITING_DESIGNER_ACCEPTANCE = 'AWAITING_DESIGNER_ACCEPTANCE',
  AWAITING_STL_VERIFICATION = 'AWAITING_STL_VERIFICATION',
  AWAITING_SPECIALIST_ACCEPTANCE = 'AWAITING_SPECIALIST_ACCEPTANCE',
  AWAITING_INSTRUCTION = 'AWAITING_INSTRUCTION',
  ON_HOLD = 'ON_HOLD',
  NOT_SUITABLE_PENDING = 'NOT_SUITABLE_PENDING',
  AWAITING_DESIGN = 'AWAITING_DESIGN',
  AWAITING_ADVICE = 'AWAITING_ADVICE',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
  AWAITING_DENTIST_APPROVAL = 'AWAITING_DENTIST_APPROVAL',
  APPROVED = 'APPROVED',
  REVIEW_STL_FILES = 'REVIEW_STL_FILES',
  WITH_MANUFACTURER = 'WITH_MANUFACTURER',
  WITH_32CO = 'WITH_32CO',
  SHIPPED = 'SHIPPED',
  IN_TREATMENT = 'IN_TREATMENT',
  COMPLETED = 'COMPLETED',
  NOT_SUITABLE = 'NOT_SUITABLE',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
}

export const getStatusName = (status?: string): string => {
  const STATUS_MAP: { [key: string]: string } = {
    all: 'All',
    [ESubmissionStatus.ARCHIVED]: 'Archived',
    [ESubmissionStatus.DRAFT]: 'Draft',
    [ESubmissionStatus.AWAITING_FILES]: 'Awaiting Files',
    [ESubmissionStatus.SUBMITTED]: 'New Cases',
    [ESubmissionStatus.AWAITING_DESIGNER_ACCEPTANCE]: 'Awaiting Designer Acceptance',
    [ESubmissionStatus.AWAITING_STL_VERIFICATION]: 'Awaiting STL Verification',
    [ESubmissionStatus.AWAITING_SPECIALIST_ACCEPTANCE]: 'Awaiting Specialist Acceptance',
    [ESubmissionStatus.AWAITING_INSTRUCTION]: 'Awaiting Instruction',
    [ESubmissionStatus.ON_HOLD]: 'On Hold',
    [ESubmissionStatus.NOT_SUITABLE_PENDING]: 'Not Suitable Pending',
    [ESubmissionStatus.AWAITING_DESIGN]: 'Awaiting Design',
    [ESubmissionStatus.AWAITING_ADVICE]: 'Awaiting Advice',
    [ESubmissionStatus.REVIEW_DESIGN]: 'Review Design',
    [ESubmissionStatus.AWAITING_DENTIST_APPROVAL]: 'Awaiting Dentist Approval',
    [ESubmissionStatus.APPROVED]: 'Approved',
    [ESubmissionStatus.REVIEW_STL_FILES]: 'Review STL Files',
    [ESubmissionStatus.WITH_MANUFACTURER]: 'With Manufacturer',
    [ESubmissionStatus.WITH_32CO]: 'With 32Co',
    [ESubmissionStatus.SHIPPED]: 'Shipped',
    [ESubmissionStatus.IN_TREATMENT]: 'In Treatment',
    [ESubmissionStatus.COMPLETED]: 'Completed',
    [ESubmissionStatus.NOT_SUITABLE]: 'Not Suitable',
    [ESubmissionStatus.EXPIRED]: 'Expired',
  };

  return status ? STATUS_MAP[status] || '' : '';
};

const FULL_DATE_FORMAT = 'MMM D, YYYY';

export const dateUtcFormat = (date: string | Moment, fr?: string) => {
  const momentDate = moment(date);
  return momentDate.utc().format(fr || FULL_DATE_FORMAT);
};

export const dateRelativeFormat = (date?: string) => {
  if (!date) return '';
  const targetDate = moment(date).startOf('day');
  const today = moment().startOf('day');

  if (targetDate.isSame(today, 'day')) return 'Today';
  // Future date
  if (targetDate.isAfter(today)) {
    const diff = targetDate.diff(today, 'days');
    return diff === 1
      ? 'Tomorrow'
      : dateUtcFormat(date, targetDate.isSame(today, 'year') ? 'ddd, MMM D' : FULL_DATE_FORMAT);
  }
  // Past date
  const diff = today.diff(targetDate, 'days');
  const diffMonth = today.diff(targetDate, 'months') || 1;
  if (diffMonth > 12) {
    const diffYear = today.diff(targetDate, 'years') || 1;
    return `${diffYear} year${diffYear > 1 ? 's' : ''} ago`;
  }
  if (diff > 28) {
    return `${diffMonth} month${diffMonth > 1 ? 's' : ''} ago`;
  }
  if (diff > 7) {
    return `${today.diff(targetDate, 'weeks')} week${today.diff(targetDate, 'weeks') > 1 ? 's' : ''} ago`;
  }
  if (diff === 7) return '1 week ago';
  if (diff > 1) return `${diff} days ago`;
  if (diff === 1) return '1 day ago';
  return '';
};

export const DD_MM_YYYY_Regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/; // 'DD-MM-YYYY'
export const DDMMYYYY_Regex = /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])(\d{4})$/; // 'DDMMYYYY'

export const isDD_MM_YYYY = (dateString: string): boolean => DD_MM_YYYY_Regex.test(dateString);

type TDobDisplayProps = {
  formatValue?: string;
  defaultVal?: string;
};

export const dobDisplay = (dateString?: string, config?: TDobDisplayProps): string => {
  const { formatValue = 'DD/MM/YYYY', defaultVal = '' } = config || {};

  if (!dateString) return defaultVal;

  if (isDD_MM_YYYY(dateString)) {
    return moment(dateString, 'DD-MM-YYYY').format(formatValue);
  }

  Sentry.captureException(new Error(`dobDisplay: dob string value ${dateString} not in format DD-MM-YYYY`));
  return moment(dateString).isValid() ? moment(dateString).format(formatValue) : defaultVal;
};

export const dobStringToMoment = <T,>(dateString?: string, defaultVal?: T): Moment | T => {
  if (!dateString) return defaultVal as T;

  if (isDD_MM_YYYY(dateString)) {
    return moment(dateString, 'DD-MM-YYYY');
  }

  Sentry.captureException(new Error(`dobStringToMoment: dob string value ${dateString} not in format DD-MM-YYYY`));
  return moment(dateString).isValid() ? moment(dateString) : (defaultVal as T);
};

export const momentToDob = (momentVal: Moment | null): string | undefined => momentVal?.format('DD-MM-YYYY');

export const calculateDiscount = (price: number, discount = 0): number => {
  const discountAmount = (price * discount) / 100;
  const finalPrice = price - discountAmount;
  return finalPrice;
};

export const getClickableLink = (link: string) => {
  if (!link) {
    return '#';
  }

  const hasProtocol = link.startsWith('http://') || link.startsWith('https://');
  const href = hasProtocol ? link : `https://${link}`;

  return href;
};

export const sIfPlural = (n: number): string => (n === 1 ? '' : 's');

const URL_REGEX = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const formatClickableContent = (text?: string) =>
  text?.replace(URL_REGEX, (url) => `<a href="${getClickableLink(url)}" target="_blank">${url}</a>`) || '';

export enum EEventNames {
  PATIENT_PROPOSAL_PATIENT_VIEW_BUTTON_CLICKED = 'Patient Proposal Patient View Button Clicked',
  PATIENT_PROPOSAL_EDIT_BUTTON_CLICKED = 'Patient Proposal Edit Button Clicked',
  PATIENT_PROPOSAL_RESEND_BUTTON_CLICKED = 'Patient Proposal Resend Button Clicked',
  PATIENT_PROPOSAL_DENTIST_REPLY = 'Patient Proposal Dentist Reply',
  PATIENT_PROPOSAL_PATIENT_REPLY = 'Patient Proposal Patient Reply',
}

export const getEstimatedDeliveryDate = (
  eddObj: {
    outboundExpectedDeliveryDate?: string;
    minExpectedDeliveryDate?: string;
    maxExpectedDeliveryDate?: string;
    expectedDeliveryDate?: string;
  },
  format: string | Function = 'ddd D MMM',
): string => {
  const formatFunc = typeof format === 'function' ? format : (date: string) => moment(date).format(format);
  if (eddObj.outboundExpectedDeliveryDate) return formatFunc(eddObj.outboundExpectedDeliveryDate);
  if (eddObj.minExpectedDeliveryDate && eddObj.maxExpectedDeliveryDate) {
    const minDate = formatFunc(eddObj.minExpectedDeliveryDate);
    const maxDate = formatFunc(eddObj.maxExpectedDeliveryDate);
    if (minDate === maxDate) return minDate;
    return `${minDate} - ${maxDate}`;
  }
  return formatFunc(eddObj.expectedDeliveryDate);
};
