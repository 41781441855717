import './popconfirm-delete.less';
import { FC, ReactNode, useState } from 'react';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue';
import { Popover, Popconfirm } from 'antd';

export interface IPopconfirmDeleteProps extends Omit<PopconfirmProps, 'title'> {
  title?: ReactNode | RenderFunction;
  size?: 'small' | 'large';
}

export const PopconfirmDelete: FC<IPopconfirmDeleteProps> = ({ children, size, ...rest }) => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleHoverChange = (visible: boolean) => {
    setHovered(visible);
  };

  const handleClickChange = (visible: boolean) => {
    setHovered(false);
    setClicked(visible);
  };

  return (
    <Popover
      content={<div className="text-center">Delete</div>}
      trigger="hover"
      open={hovered && !clicked}
      onOpenChange={handleHoverChange}
    >
      <Popconfirm
        title={
          <div className="popconfirm-delete-title !divide-y divide-solid">
            <p className="mb-0 text-sm">Delete</p>
            <div className="popconfirm-delete-divider" />
            <p className="popconfirm-delete-description !border-none">Please confirm you want to delete?</p>
          </div>
        }
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{
          danger: true,
          className: 'popconfirm-delete-buttons',
          size,
          shape: 'default',
        }}
        cancelButtonProps={{
          className: 'popconfirm-delete-buttons',
          shape: 'default',
          size,
        }}
        icon={false}
        onOpenChange={handleClickChange}
        {...rest}
      >
        {children}
      </Popconfirm>
    </Popover>
  );
};
