import { ISubmission } from 'interface/operations';
import { api, VERSION } from 'services/api';
import {
  ITimeline,
  TAssignSubmissionRequest,
  TAssignSubmissionResponse,
  TSubmissionsRequest,
  TRejectedReasonsExtended,
  TUploadSTLFileRequest,
  TSubmissionsResponse,
  TUpdateSubmissionRequest,
  TArchiveStatusRequest,
  TUpdateSubmissionStatusRequest,
  TUpdateShippingLinkRequest,
  TUpdateMultipleSubmissionsRequest,
  TSubmissionsCountResponse,
  IPatientCheckInResponse,
  TReAssignSubmissionRequest,
} from './operationsTypes';
import { SUBMISSION_LISTING_TARGET_MAPPING } from 'pages/operations/consts';
import { buildQueryString } from 'services/utils';
import { ESubmissionStatus } from '@repo/ui/src/utils';

const SUBMISSION_URL = `/${VERSION}/admin/submissions`;
const DASHBOARD_SUBMISSION_URL = `/${VERSION}/admin/dashboard/submissions`;

export const buildCommonSubmissionQuery = (params: TSubmissionsRequest): string => {
  const { page, perPage, status, q, isPriority, requireAttention, sort, order } = params;
  return buildQueryString({ page, perPage, status, q, isPriority, requireAttention, sort, order });
};

const buildSubmissionsProvidesTags = (result?: TSubmissionsResponse): { type: 'SubmissionsView'; id: string }[] =>
  result
    ? [
        // Provides a tag for each case in the current page,
        // as well as the 'PARTIAL-LIST' tag.
        ...result.docs.map(({ id }) => ({ type: 'SubmissionsView' as const, id })),
        { type: 'SubmissionsView', id: 'PARTIAL-LIST' },
      ]
    : [{ type: 'SubmissionsView', id: 'PARTIAL-LIST' }];

export const operationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getListSubmissions: builder.query<TSubmissionsResponse, TSubmissionsRequest>({
      query: (params) => {
        const paramsString = buildQueryString(params);
        // return `/v2/admin/submissions?${paramsString}`;
        return `${SUBMISSION_URL}?${paramsString}`;
      },
      providesTags: (result) => buildSubmissionsProvidesTags(result),
    }),
    getListSubmissionsListing: builder.query<TSubmissionsResponse, TSubmissionsRequest>({
      query: ({ submissionTarget, ...restParams }) => {
        let queryString = buildCommonSubmissionQuery(restParams);

        if (submissionTarget) {
          const targetValue = SUBMISSION_LISTING_TARGET_MAPPING[submissionTarget];
          queryString += `&target=${targetValue}`;
        }
        return `${DASHBOARD_SUBMISSION_URL}?${queryString}`;
      },

      providesTags: (result) => buildSubmissionsProvidesTags(result),
    }),
    getListSubmissionsCount: builder.query<TSubmissionsCountResponse, { status?: keyof typeof ESubmissionStatus }>({
      query: (params) => `${SUBMISSION_URL}/count?${buildCommonSubmissionQuery(params)}`,
      providesTags: () => buildSubmissionsProvidesTags(),
    }),
    updateShippingLink: builder.mutation<void, TUpdateShippingLinkRequest>({
      query(payload) {
        return {
          url: `/${VERSION}/admin/submissions/shipping`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: [
        { type: 'SubmissionsView', id: 'LIST' },
        { type: 'Orders', id: 'LIST' },
      ],
    }),
    updateSubmissionStatus: builder.mutation<void, TUpdateSubmissionStatusRequest>({
      query({ id, payload }) {
        return {
          url: `/${VERSION}/admin/submissions/${id}/status`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, _error, args) => [{ type: 'SubmissionsView', id: args.id }],
    }),

    assignSubmission: builder.mutation<TAssignSubmissionResponse, TAssignSubmissionRequest>({
      query({ id, payload }) {
        return {
          url: `${SUBMISSION_URL}/${id}/assign`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'SubmissionsView', id: 'LIST' }],
    }),
    reAssignSubmission: builder.mutation<TAssignSubmissionResponse, TReAssignSubmissionRequest>({
      query({ id, payload }) {
        return {
          url: `${SUBMISSION_URL}/${id}/re-assign`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'SubmissionsView', id: 'LIST' }],
    }),
    updateShowNotification: builder.mutation<any, any>({
      query({ id, body }) {
        return {
          url: `/admin/${VERSION}/cases/${id}/view-messages`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: 'SubmissionsView', id: 'LIST' }],
    }),
    uploadStlFiles: builder.mutation<void, TUploadSTLFileRequest>({
      query({ payload, submissionId }) {
        return {
          url: `${SUBMISSION_URL}/${submissionId}/stls`,
          method: 'PUT',
          body: payload,
        };
      },
    }),
    archiveSubmissions: builder.mutation<void, { caseIds: string[] }>({
      query({ caseIds }) {
        return {
          url: `${SUBMISSION_URL}/archive`,
          method: 'POST',
          body: { ids: caseIds },
        };
      },
      invalidatesTags: (_result, _error, arg) => arg.caseIds.map((id) => ({ type: 'SubmissionsView', id })),
    }),
    getTimeline: builder.query<ITimeline[], { submissionId: string }>({
      query({ submissionId }) {
        return `${SUBMISSION_URL}/${submissionId}/timelines`;
      },
      providesTags: (_result, _error, { submissionId }) => [{ type: 'SubmissionTimeline', id: submissionId }],
    }),
    getSubmission: builder.query<ISubmission, { id: string }>({
      query({ id }) {
        return `${SUBMISSION_URL}/${id}`;
      },
      providesTags: (_result, _error, { id }) => [{ type: 'SubmissionsView', id }],
    }),
    togglePriorityRequest: builder.mutation<any, { id: string }>({
      query({ id }) {
        return {
          url: `${SUBMISSION_URL}/${id}/priority`,
          method: 'PUT',
        };
      },
      invalidatesTags: [{ type: 'SubmissionsView', id: 'PARTIAL-LIST' }],
    }),
    updateNotSuitableReasons: builder.mutation<
      { submissionId: string },
      { submissionId: string; payload: TRejectedReasonsExtended }
    >({
      query({ submissionId, payload }) {
        return {
          url: `${SUBMISSION_URL}/${submissionId}/approve-unsuitable`,
          method: 'PUT',
          body: payload,
        };
      },
    }),
    updateSubmission: builder.mutation<void, TUpdateSubmissionRequest>({
      query({ id, payload }) {
        return {
          url: `/${VERSION}/admin/submissions/${id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, error, arg) => {
        if (error) return [];
        return [
          { type: 'Cases', id: arg?.id },
          { type: 'SubmissionsView', id: arg?.id },
          { type: 'SubmissionTimeline', id: arg?.id },
        ];
      },
    }),
    updateMultipleSubmissionsStatus: builder.mutation<void, TUpdateMultipleSubmissionsRequest>({
      query(payload) {
        return {
          url: `/${VERSION}/admin/submissions/status`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, error, args) => {
        if (error) return [];
        return args?.submissions?.map((arg) => ({ type: 'Cases', id: arg?.submissionId }));
      },
    }),
    updateArchiveStlStatus: builder.mutation<{ message: string }, TArchiveStatusRequest>({
      query({ submissionId, payload }) {
        return {
          url: `/${VERSION}/admin/submissions/${submissionId}/archive-stls`,
          method: 'PUT',
          body: payload,
        };
      },
    }),
    getCheckIns: builder.query<IPatientCheckInResponse[], { submissionId: string }>({
      query({ submissionId }) {
        return `${SUBMISSION_URL}/${submissionId}/patient-checkings`;
      },
    }),
  }),
});

export const {
  useGetListSubmissionsQuery,
  useGetListSubmissionsListingQuery,
  useGetListSubmissionsCountQuery,
  useUpdateShippingLinkMutation,
  useUpdateSubmissionStatusMutation,
  useAssignSubmissionMutation,
  useReAssignSubmissionMutation,
  useUpdateShowNotificationMutation,
  useUploadStlFilesMutation,
  useArchiveSubmissionsMutation,
  useGetTimelineQuery,
  useGetSubmissionQuery,
  useTogglePriorityRequestMutation,
  useUpdateNotSuitableReasonsMutation,
  useUpdateSubmissionMutation,
  useUpdateArchiveStlStatusMutation,
  useUpdateMultipleSubmissionsStatusMutation,
  useGetCheckInsQuery,
} = operationsApi;
